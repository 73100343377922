import { NbToastRef, NbToastrService, NbGlobalPhysicalPosition } from '@nebular/theme';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastrContainerService {
  private toastrContainer: NbToastRef[] = [];

  constructor(private toastrService: NbToastrService) {}

  push(toastrMessage: string) {
    if (this.toastrContainer.length >= 1) {
      const length = this.toastrContainer[0]['toastContainer'].toasts.filter((x) => x.message == toastrMessage).length;
      if (length === 0) {
        this.toastrContainer.push(
          this.toastrService.show(toastrMessage, 'Error', {
            position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
            icon: 'close-outline',
            duration: 1000000,
            status: 'danger',
            preventDuplicates: true,
          }),
        );
      }
    } else {
      this.toastrContainer.push(
        this.toastrService.show(toastrMessage, 'Error', {
          position: NbGlobalPhysicalPosition.BOTTOM_LEFT,
          icon: 'close-outline',
          duration: 1000000,
          status: 'danger',
          preventDuplicates: true,
        }),
      );
    }
  }

  pushSuccess(toastrMessage: string) {
    console.log('this.toastrContainer', this.toastrContainer);
    if (this.toastrContainer.length >= 1) {
      if (this.toastrContainer[0]['toastContainer'].toasts.filter((x) => x.message == toastrMessage).length == 0) {
        this.toastrContainer.push(
          this.toastrService.show(toastrMessage, 'Success', {
            position: NbGlobalPhysicalPosition.TOP_LEFT,
            icon: 'close-outline',
            duration: 1000000,
            status: 'success',
            preventDuplicates: true,
          }),
        );
      }
    } else {
      this.toastrContainer.push(
        this.toastrService.show(toastrMessage, 'Success', {
          position: NbGlobalPhysicalPosition.TOP_LEFT,
          icon: 'close-outline',
          duration: 1000000,
          status: 'success',
          preventDuplicates: true,
        }),
      );
    }
  }

  clear() {
    this.toastrContainer.forEach((res) => {
      res.close();
    });
    this.toastrContainer = [];
  }

  pop(msg: any) {
    this.toastrContainer.forEach((res, index) => {
      if (res['toast'].message == msg) {
        res.close();
        this.toastrContainer.splice(index, 1);
      }
    });
  }
}
