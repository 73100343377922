import { Injectable } from '@angular/core';

import { FlowTriggerModel } from '../models/flow-model.model';
import { FlowTriggersTabModel } from '../models/flow-triggers-tab.model';

@Injectable()
export class FlowSummaryPanelService {
  public days: any = [
    { id: 1, name: 'M', checked: false },
    { id: 2, name: 'T', checked: false },
    { id: 3, name: 'W', checked: false },
    { id: 4, name: 'T', checked: false },
    { id: 5, name: 'F', checked: false },
    { id: 6, name: 'S', checked: false },
    { id: 7, name: 'S', checked: false },
  ];
  public monthdays: any = [
    { id: 1, name: '1', checked: false },
    { id: 2, name: '2', checked: false },
    { id: 3, name: '3', checked: false },
    { id: 4, name: '4', checked: false },
    { id: 5, name: '5', checked: false },
    { id: 6, name: '6', checked: false },
    { id: 7, name: '7', checked: false },
    { id: 8, name: '8', checked: false },
    { id: 9, name: '9', checked: false },
    { id: 10, name: '10', checked: false },
    { id: 11, name: '11', checked: false },
    { id: 12, name: '12', checked: false },
    { id: 13, name: '13', checked: false },
    { id: 14, name: '14', checked: false },
    { id: 15, name: '15', checked: false },
    { id: 16, name: '16', checked: false },
    { id: 17, name: '17', checked: false },
    { id: 18, name: '18', checked: false },
    { id: 19, name: '19', checked: false },
    { id: 20, name: '20', checked: false },
    { id: 21, name: '21', checked: false },
    { id: 22, name: '22', checked: false },
    { id: 23, name: '23', checked: false },
    { id: 24, name: '24', checked: false },
    { id: 25, name: '25', checked: false },
    { id: 26, name: '26', checked: false },
    { id: 27, name: '27', checked: false },
    { id: 28, name: '28', checked: false },
    { id: 29, name: '29', checked: false },
    { id: 30, name: '30', checked: false },
    { id: 31, name: '31', checked: false },
    { id: 32, name: 'Last Day of the Month', checked: false },
  ];
  public months: any = [
    { id: 1, name: 'January', checked: false },
    { id: 2, name: 'February', checked: false },
    { id: 3, name: 'March', checked: false },
    { id: 4, name: 'April', checked: false },
    { id: 5, name: 'May', checked: false },
    { id: 6, name: 'June', checked: false },
    { id: 7, name: 'July', checked: false },
    { id: 8, name: 'August', checked: false },
    { id: 9, name: 'September', checked: false },
    { id: 10, name: 'October', checked: false },
    { id: 11, name: 'November', checked: false },
    { id: 12, name: 'December', checked: false },
  ];
  public schedules: any[] = [
    {
      ID: 1,
      name: 'Hourly',
    },
    {
      ID: 2,
      name: 'Daily',
    },
    {
      ID: 3,
      name: 'Weekly',
    },
    {
      ID: 4,
      name: 'Monthly',
    },
    {
      ID: 5,
      name: 'On specific dates',
    },
    {
      ID: 7,
      name: 'Event based',
    },
  ];

  constructor() {}

  // async getBoth(currentTriggerBox, flowTriggers: FlowTriggersTabModel) {
  //   let flowBoth = {
  //     currentTriggerBox: this.getCurrentTriggerBox(currentTriggerBox),
  //     currentTrigger: await this.getCurrentTrigger(flowTriggers),
  //   };
  //   return flowBoth;
  // }

  getCurrentTriggerBox(currentTriggerBox) {
    return currentTriggerBox === 1 ? 'on a schedule' : currentTriggerBox === 2 ? 'on specific event' : null;
  }

  getFlowTriggersIsTemp(flowTriggers: FlowTriggersTabModel) {
    if (flowTriggers.scheduleOptionID == 3) {
      this.days[0].checked = flowTriggers.day1;
      this.days[1].checked = flowTriggers.day2;
      this.days[2].checked = flowTriggers.day3;
      this.days[3].checked = flowTriggers.day4;
      this.days[4].checked = flowTriggers.day5;
      this.days[5].checked = flowTriggers.day6;
      this.days[6].checked = flowTriggers.day7;
    }
    if (flowTriggers.scheduleOptionID == 4) {
      this.monthdays[0].checked = flowTriggers.monthday1;
      this.monthdays[1].checked = flowTriggers.monthday2;
      this.monthdays[2].checked = flowTriggers.monthday3;
      this.monthdays[3].checked = flowTriggers.monthday4;
      this.monthdays[4].checked = flowTriggers.monthday5;
      this.monthdays[5].checked = flowTriggers.monthday6;
      this.monthdays[6].checked = flowTriggers.monthday7;
      this.monthdays[7].checked = flowTriggers.monthday8;
      this.monthdays[8].checked = flowTriggers.monthday9;
      this.monthdays[9].checked = flowTriggers.monthday10;
      this.monthdays[10].checked = flowTriggers.monthday11;
      this.monthdays[11].checked = flowTriggers.monthday12;
      this.monthdays[12].checked = flowTriggers.monthday13;
      this.monthdays[13].checked = flowTriggers.monthday14;
      this.monthdays[14].checked = flowTriggers.monthday15;
      this.monthdays[15].checked = flowTriggers.monthday16;
      this.monthdays[16].checked = flowTriggers.monthday17;
      this.monthdays[17].checked = flowTriggers.monthday18;
      this.monthdays[18].checked = flowTriggers.monthday19;
      this.monthdays[19].checked = flowTriggers.monthday20;
      this.monthdays[20].checked = flowTriggers.monthday21;
      this.monthdays[21].checked = flowTriggers.monthday22;
      this.monthdays[22].checked = flowTriggers.monthday23;
      this.monthdays[23].checked = flowTriggers.monthday24;
      this.monthdays[24].checked = flowTriggers.monthday25;
      this.monthdays[25].checked = flowTriggers.monthday26;
      this.monthdays[26].checked = flowTriggers.monthday27;
      this.monthdays[27].checked = flowTriggers.monthday28;
      this.monthdays[28].checked = flowTriggers.monthday29;
      this.monthdays[29].checked = flowTriggers.monthday30;
      this.monthdays[30].checked = flowTriggers.monthday31;
      this.monthdays[31].checked = flowTriggers.monthday32;
    }
    if (flowTriggers.scheduleOptionID == 5) {
      this.months[0].checked = flowTriggers.month1;
      this.months[1].checked = flowTriggers.month2;
      this.months[2].checked = flowTriggers.month3;
      this.months[3].checked = flowTriggers.month4;
      this.months[4].checked = flowTriggers.month5;
      this.months[5].checked = flowTriggers.month6;
      this.months[6].checked = flowTriggers.month7;
      this.months[7].checked = flowTriggers.month8;
      this.months[8].checked = flowTriggers.month9;
      this.months[9].checked = flowTriggers.month10;
      this.months[10].checked = flowTriggers.month11;
      this.months[11].checked = flowTriggers.month12;
      this.monthdays[0].checked = flowTriggers.monthday1;
      this.monthdays[1].checked = flowTriggers.monthday2;
      this.monthdays[2].checked = flowTriggers.monthday3;
      this.monthdays[3].checked = flowTriggers.monthday4;
      this.monthdays[4].checked = flowTriggers.monthday5;
      this.monthdays[5].checked = flowTriggers.monthday6;
      this.monthdays[6].checked = flowTriggers.monthday7;
      this.monthdays[7].checked = flowTriggers.monthday8;
      this.monthdays[8].checked = flowTriggers.monthday9;
      this.monthdays[9].checked = flowTriggers.monthday10;
      this.monthdays[10].checked = flowTriggers.monthday11;
      this.monthdays[11].checked = flowTriggers.monthday12;
      this.monthdays[12].checked = flowTriggers.monthday13;
      this.monthdays[13].checked = flowTriggers.monthday14;
      this.monthdays[14].checked = flowTriggers.monthday15;
      this.monthdays[15].checked = flowTriggers.monthday16;
      this.monthdays[16].checked = flowTriggers.monthday17;
      this.monthdays[17].checked = flowTriggers.monthday18;
      this.monthdays[18].checked = flowTriggers.monthday19;
      this.monthdays[19].checked = flowTriggers.monthday20;
      this.monthdays[20].checked = flowTriggers.monthday21;
      this.monthdays[21].checked = flowTriggers.monthday22;
      this.monthdays[22].checked = flowTriggers.monthday23;
      this.monthdays[23].checked = flowTriggers.monthday24;
      this.monthdays[24].checked = flowTriggers.monthday25;
      this.monthdays[25].checked = flowTriggers.monthday26;
      this.monthdays[26].checked = flowTriggers.monthday27;
      this.monthdays[27].checked = flowTriggers.monthday28;
      this.monthdays[28].checked = flowTriggers.monthday29;
      this.monthdays[29].checked = flowTriggers.monthday30;
      this.monthdays[30].checked = flowTriggers.monthday31;
      this.monthdays[31].checked = flowTriggers.monthday32;
    }
  }

  getFlowTriggersIsNotTemp(flowTriggers: FlowTriggersTabModel) {
    if (flowTriggers.whichDayOfWeek) {
      this.days = this.resetChecked(this.days);
      flowTriggers.whichDayOfWeek.forEach((item) => {
        for (const day of this.days) {
          if (day.id === item) {
            this.days[item - 1].checked = true;
          }
        }
      });
    }
    if (flowTriggers.scheduleOptionID == 4) {
      if (flowTriggers.whichDate) {
        this.monthdays = this.resetChecked(this.monthdays);
        flowTriggers.whichDate.forEach((itemm) => {
          for (const monthday of this.monthdays) {
            if (monthday.id === itemm) {
              this.monthdays[itemm - 1].checked = true;
            }
          }
        });
      }
    }
    if (flowTriggers.scheduleOptionID == 5) {
      if (flowTriggers.whichDates) {
        this.monthdays = this.resetChecked(this.monthdays);
        flowTriggers.whichDates.forEach((itemm) => {
          for (const monthDay of this.monthdays) {
            if (monthDay.id === itemm) {
              this.monthdays[itemm - 1].checked = true;
            }
          }
        });
      }
    }

    if (flowTriggers.whichMonth) {
      flowTriggers.whichMonth.forEach((itemwm) => {
        this.months = this.resetChecked(this.months);
        for (const month of this.months) {
          if (month.id === itemwm) {
            this.months[itemwm - 1].checked = true;
          }
        }
      });
    }
  }

  getCurrentTrigger(flowTriggers: FlowTriggerModel): string {
    if (!flowTriggers) {
      return null;
    }

    let yo = 'Empty';

    let scheduleid = 0;
    if (flowTriggers.scheduleOptionID && flowTriggers.scheduleOptionID !== -1) {
      scheduleid = this.schedules[flowTriggers.scheduleOptionID - 1].ID;
      if (scheduleid === 1) {
        yo =
          'Every ' + (flowTriggers.repeatEveryHourMinuteValue === null ? '0' : flowTriggers.repeatEveryHourMinuteValue);
        if (!flowTriggers.repeatEveryHourMinute) {
          return yo + '';
        }
        yo +=
          flowTriggers.repeatEveryHourMinute === 'hour'
            ? ' hours'
            : flowTriggers.repeatEveryHourMinute === 'minute'
              ? ' minutes'
              : ' hours/minutes';
        return yo;
      } else if (scheduleid === 2) {
        if (flowTriggers.specificTimeBetween === 'specific time') {
          yo = 'Daily' + '\n' + 'At ' + flowTriggers.timeFrom;
          return yo;
        }
        if (flowTriggers.specificTimeBetween === 'between') {
          yo =
            'Daily' +
            '\n' +
            'Between ' +
            flowTriggers.timeFrom +
            ' to ' +
            flowTriggers.timeTo +
            ' every ' +
            (flowTriggers.repeatEveryHourMinuteValue === null ? '0' : flowTriggers.repeatEveryHourMinuteValue);
          yo +=
            flowTriggers.repeatEveryHourMinute === 'hour'
              ? ' hours'
              : flowTriggers.repeatEveryHourMinute === 'minute'
                ? ' minutes'
                : ' hours/minutes';
          return yo;
        }
      } else if (scheduleid === 3) {
        yo = 'Every ';
        for (let i of this.days) {
          if (i.checked === true) {
            yo +=
              i.id === 1
                ? 'Monday, '
                : i.id === 2
                  ? 'Tuesday, '
                  : i.id === 3
                    ? 'Wednesday, '
                    : i.id === 4
                      ? 'Thursday, '
                      : i.id === 5
                        ? 'Friday, '
                        : i.id === 6
                          ? 'Saturday, '
                          : i.id === 7
                            ? 'Sunday,'
                            : null;
          }
        }
        yo = yo.replace(/,(?=[^,]*$)/, '');
        yo = yo.replace(/,(?=[^,]*$)/, ' and ');
        if (flowTriggers.specificTimeBetween === 'specific time') {
          yo += '\n' + ' At ' + flowTriggers.timeFrom;
        }
        if (flowTriggers.specificTimeBetween === 'between') {
          yo +=
            '\n' +
            ' Between ' +
            flowTriggers.timeFrom +
            ' to ' +
            flowTriggers.timeTo +
            ' every ' +
            (flowTriggers.repeatEveryHourMinuteValue === null ? '0' : flowTriggers.repeatEveryHourMinuteValue);
          yo +=
            flowTriggers.repeatEveryHourMinute === 'hour'
              ? ' hours'
              : flowTriggers.repeatEveryHourMinute === 'minute'
                ? ' minutes'
                : ' hours/minutes';
        }
        if (yo !== 'Every ') {
          return yo;
        }
      } else if (scheduleid === 4) {
        yo = 'Every ';
        let cntflag = 0;
        for (let j of this.monthdays) {
          if (j.checked === true) {
            if (j.id !== 32) {
              yo += j.id + this.ordinal(j.id) + ', ';
            } else {
              yo += 'Last day, ';
            }
            cntflag += 1;
          }
        }
        if (cntflag > 0) {
          yo = yo.replace(/,(?=[^,]*$)/, '');
          yo = yo.replace(/,(?=[^,]*$)/, ' and ');
          yo += ' of the month ';
        }
        if (flowTriggers.specificTimeBetween === 'specific time') {
          yo += '\n' + ' At ' + flowTriggers.timeFrom;
        }
        if (flowTriggers.specificTimeBetween === 'between') {
          yo +=
            '\n' +
            ' Between ' +
            flowTriggers.timeFrom +
            ' to ' +
            flowTriggers.timeTo +
            ' every ' +
            (flowTriggers.repeatEveryHourMinuteValue === null ? '0' : flowTriggers.repeatEveryHourMinuteValue);
          yo +=
            flowTriggers.repeatEveryHourMinute === 'hour'
              ? ' hours'
              : flowTriggers.repeatEveryHourMinute === 'minute'
                ? ' minutes'
                : ' hours/minutes';
        }
        if (yo !== 'Every ') {
          return yo;
        }
      } else if (scheduleid === 5) {
        yo = '';
        if (flowTriggers.whichMonth) {
          let cntmonth = 0;
          let monthString =
            flowTriggers.whichMonth
              .map((monthId) => {
                cntmonth++;
                return this.months.filter(({ id }) => id === monthId)[0]?.name || '';
              })
              .join(', ') + ' ';
          if (cntmonth > 1) {
            monthString = monthString.replace(/,(?=[^,]*$)/, ' and ');
          }
          yo += monthString;
        }

        if (flowTriggers?.whichYear?.length) {
          yo += `${flowTriggers.whichYear.join(', ')} `;
        }

        let chosendays = '';
        let cntdays = 0;

        for (let j of this.monthdays) {
          if (j.checked === true) {
            if (j.id !== 32) {
              chosendays += j.id + this.ordinal(j.id) + ', ';
            } else {
              chosendays += 'Last day, ';
            }
            cntdays += 1;
          }
        }
        if (cntdays > 0) {
          yo += '\n' + 'On the ';
          yo += chosendays;
          yo = yo.replace(/,(?=[^,]*$)/, '');
          yo = yo.replace(/,(?=[^,]*$)/, ' and ');
          yo += ' of the month ';
        }
        if (flowTriggers.specificTimeBetween === 'specific time') {
          yo += '\n' + ' At ' + flowTriggers.timeFrom;
        }
        if (flowTriggers.specificTimeBetween === 'between') {
          yo +=
            '\n' +
            ' Between ' +
            flowTriggers.timeFrom +
            ' to ' +
            flowTriggers.timeTo +
            ' every ' +
            (flowTriggers.repeatEveryHourMinuteValue === null ? '0' : flowTriggers.repeatEveryHourMinuteValue);
          yo +=
            flowTriggers.repeatEveryHourMinute === 'hour'
              ? ' hours'
              : flowTriggers.repeatEveryHourMinute === 'minute'
                ? ' minutes'
                : ' hours/minutes';
        }
        if (yo.length) {
          return yo;
        }
      }
    }
  }

  ordinal(n) {
    return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
  }

  resetChecked(data: { checked: boolean }[]) {
    return data.map((item) => ({
      ...item,
      checked: false,
    }));
  }

  /** affter restructuring: **/

  updateFlowActionsSummary(actions) {}
}
